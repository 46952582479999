import * as React from "react";
import { graphql } from "gatsby";
import { useIntl } from "react-intl";
import { LocalizedLink } from "gatsby-theme-i18n";
import Layout from "../components/layout";
import Seo from "../components/seo";
import BoutiqueMap from "../components/map/map";
import VideoHero from "../components/videoHero";
import Container from "../components/container";
import Section from "../components/section";
import { Heading } from "../components/typo";
import { Grid, Box, Image, Text, ResponsiveContext } from "grommet";
import { useContext } from "react";
import styled, { css } from "styled-components";
import { BoutiqueList } from "../components/BoutiqueList";
import ReactPlayer from 'react-player'

const BtnInverse = styled(LocalizedLink)`
  display: block;
  text-decoration: none;
  border: 2px solid #fff;
  border-radius: 0;
  color: #fff;
  display: inline-block;
  font-size: 20px;
  font-weight: 700;
  padding: 15px 30px;
  justify-self: center;
  transition: all 0.3s;
  margin-top: 1em;

  &:hover,
  &:focus,
  &:active {
    background-color: #fff;
    box-shadow: none;
    color: #014165;
    text-decoration: none;
  }
`;

const Btn = styled(LocalizedLink)`
  display: block;
  text-decoration: none;
  border: 2px solid #014165;
  border-radius: 0;
  color: #014165;
  display: inline-block;
  font-size: 20px;
  font-weight: 700;
  padding: 15px 30px;
  justify-self: flex-start;
  transition: all 0.3s;
  margin-top: 1em;

  &:hover,
  &:focus,
  &:active {
    background-color: #014165;
    box-shadow: none;
    color: #fff;
    text-decoration: none;
  }
`;

const Index = ({ data, pageContext }) => {
  const intl = useIntl();
  const locale = pageContext.locale;
  const originalPath = pageContext.originalPath;
  const size = useContext(ResponsiveContext);

  return (
    <Layout locale={locale} originalPath={originalPath} homeHeader>
      <Seo title={intl.formatMessage({ id: "page-home" })} />
      <VideoHero boutiques={data.allMdx.nodes} />
      <Section
        style={{
          //backgroundColor: "#00202F",
          //padding: size === "max-width: 700px" ? "40px 0" : "120px 0",
          marginTop: "40px",
        }}
      >
        <Container style={{ textAlign: "center" }}>
          <ReactPlayer
            light={<img src="/cover.png" alt="" />}
            url='https://large-media.s3.eu-central-1.amazonaws.com/Teaser_RIC_GVA_BTQ_EXP_23_V2.mp4'
            controls={true}
            volume={1}
            playing={true}
            width="100%"
            height="100%"
          />
        </Container>
      </Section>
      <Section
        style={{
          //backgroundColor: "#00202F",
          //padding: size === "max-width: 700px" ? "40px 0" : "120px 0",
          marginTop: "40px",
        }}
      >
        <Container style={{ textAlign: "center" }}>
          <Box
            style={{
              padding: size === "small" || size === "tiny" ? "20px" : "20px",
            }}
          >
            <Heading
              level={2}
              style={{
                fontWeight: 400,
              }}
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: "home-block-1-text" }),
              }}
            />
            <Text
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: "home-block-1-subtext" }),
              }}
            />
            <div>
              <Btn to="/registration/">
                {intl.formatMessage({ id: "btn-registration" })}
              </Btn>
            </div>
          </Box>
        </Container>
      </Section>
      <Section>
        <Heading
          textAlign="center"
          level="2"
          style={{
            margin: "0 auto",
            width: "100%",
            maxWidth: "1200px",
            padding: "0 30px 50px 30px",
          }}
        >
          {intl.formatMessage({ id: "map" })}
        </Heading>
        <BoutiqueList boutiques={data.allMdx.nodes} />
      </Section>
      {/* <Section>
        <Container
          direction="column"
          style={{
            maxWidth: "100%",
            height: "fit-content",
            padding: "0px",
            paddingTop: "50px",
          }}
        >
          <Heading
            level="2"
            style={{
              margin: "0 auto",
              width: "100%",
              maxWidth: "1200px",
              padding: "0 30px 50px 30px",
            }}
          >
            {intl.formatMessage({ id: "map" })}
          </Heading>
          <BoutiqueMap boutiques={data.allMdx.nodes} />
        </Container>
      </Section> */}
      <Section>
        <Container>
          <Grid
            gap="medium"
            columns={
              size === "small" || size === "tiny" ? null : ["1fr", "1fr"]
            }
          >
            <Box>
              <Heading level="3" style={{ margin: 0, padding: 0 }}>
                {intl.formatMessage({ id: "home-block-2-title" })}
              </Heading>
              <p style={{ fontSize: "20px", lineHeight: "27px" }}>
                {intl.formatMessage({ id: "home-block-2-text" })}
              </p>
              <div>
                <Btn to="/registration/">
                  {intl.formatMessage({ id: "btn-registration" })}
                </Btn>
              </div>
            </Box>
            <Box height={{ max: "350px" }}>
              <Image src="/concours-home.jpg?v=2" fit="contain" />
            </Box>
          </Grid>
        </Container>
      </Section>
    </Layout>
  );
};

export default Index;

export const query = graphql`
  query ($locale: String!) {
    allMdx(
      filter: { frontmatter: { published: { eq: true }, language: { eq: $locale } } }
      sort: { fields: frontmatter___name, order: ASC }
    ) {
      nodes {
        frontmatter {
          id
          language
          city
          address
          image {
            childImageSharp {
              gatsbyImageData(
                width: 800
                height: 580
                placeholder: BLURRED
                transformOptions: { fit: CONTAIN }
              )
            }
          }
          phoneNumber
          openingHour
          openingTime
          npa
          email
          textExperience
          logo {
            path {
              publicURL
            }
          }
        }
      }
    }
  }
`;
