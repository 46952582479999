import React from "react";
import { Image, Box } from "grommet";
import { useIntl } from "react-intl";
import ItineraryButton from "./map/itineraryButton";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styled from "styled-components";

const Teaser = styled(Box)`
  font-size: 14px;
  line-height: 1.35;
  padding: 20px 10px;

  @media (min-width: 680px) {
    font-size: 20px;
    padding: 30px;
  }
`;

const LogoImageWrapper = styled.div`
  @media (max-width: 767px) {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 100px 180px;
  }
`;

const LogoWrapper = styled.div`
  margin: 15px auto 20px;
  height: 60px;
  min-width: 200px;
  max-width: 220px;

  @media (min-width: 680px) {
    max-width: 300px;
    height: 80px;
  }

  @media (max-width: 767px) {
      height: auto;
      width: 100%;
    min-width: 100%;
  }
`;

const BoutiqueTeaser = ({
  logo,
  image,
  address,
  npa,
  city,
  phoneNumber,
  openingTime,
  textExperience,
  email,
  active,
}) => {
  const intl = useIntl();

  if (!active) {
    return null;
  }

  return (
    <Teaser background="#014165">
      <LogoImageWrapper>
        <GatsbyImage image={getImage(image)} alt="experience" />
        <LogoWrapper>
          <Image fill fit="contain" src={logo.path.publicURL} alt="logo" />
        </LogoWrapper>
      </LogoImageWrapper>
      <Box>
        {textExperience && (
          <p margin="none">
            <span dangerouslySetInnerHTML={{ __html: textExperience }} />
          </p>
        )}
        {phoneNumber && (
          <p>
            Tél:&nbsp;
            <a
              href={"Tél:" + phoneNumber}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              {phoneNumber}
            </a>
            <br />
            {email}
          </p>
        )}
        {address && (
          <p>
            <span dangerouslySetInnerHTML={{ __html: address }} />, {npa} {city}
          </p>
        )}
        {openingTime && (
          <p>
            <b>{intl.formatMessage({ id: "openingHour" })}:</b>
            <br />
            <span dangerouslySetInnerHTML={{ __html: openingTime }} />
          </p>
        )}
      </Box>
      <ItineraryButton
        href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
          address + ", " + npa + " " + city
        )}`}
      />
    </Teaser>
  );
};
export { BoutiqueTeaser };
