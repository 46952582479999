import React, { useContext, useState } from "react";
import { Grid, Box, ResponsiveContext, Button } from "grommet";
import MapBoutiqueTeaser from "./mapBoutiqueTeaser";
import Map from "./mapMap";
import ArrowUp from "./arrowUp";
import ArrowDown from "./arrowDown";
import styled from "styled-components";

const StyledBox = styled(Box)`
  width: 48px;
  position: absolute;
  z-index: 5;

  @media screen and (max-width: 1006px) {
    left: 50%;
    top: -24px;
    flex-direction: row-reverse;
  }

  @media screen and (min-width: 1007px) {
    right: -48px;
    top: 210px;
  }
`;
const TeaserContainer = styled(Box)`
  position: relative;

  @media (max-width: 1006px) {
    grid-row: 2 / 3;
  }
`;

const MapContainer = styled(Box)`
  @media (max-width: 1006px) {
    grid-row: 1 / 2;
  }
`;

const BoutiqueMap = ({ boutiques }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const size = useContext(ResponsiveContext);
  return (
    <>
      <Grid
        columns={size === "tiny" || size === "small" ? null : ["450px", "1fr"]}
        style={{ minHeight: "750px" }}
      >
        <TeaserContainer background="#041224">
          <StyledBox>
            <Button
              height="48px"
              style={{
                display: "flex",
                justifyContent: "center",
                margin: size === "tiny" || size === "small" ? "0 10px" : "0",
                backgroundColor: "#00202F",
              }}
              onClick={() => {
                if (activeIndex - 1 < 0) {
                  setActiveIndex(boutiques.length - 1);
                } else {
                  setActiveIndex(activeIndex - 1);
                }
              }}
            >
              <ArrowUp />
            </Button>
            <Button
              height="48px"
              style={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#00202F",
              }}
              onClick={() => {
                if (activeIndex + 1 === boutiques.length) {
                  setActiveIndex(0);
                } else {
                  setActiveIndex(activeIndex + 1);
                }
              }}
            >
              <ArrowDown />
            </Button>
          </StyledBox>
          {boutiques.map((boutique, i) => (
            <MapBoutiqueTeaser
              height="100%"
              background="#041224"
              justifyContent="center"
              key={i}
              {...boutique.frontmatter}
              active={activeIndex === i}
            />
          ))}
        </TeaserContainer>
        <MapContainer background="light-2">
          <Map
            onMarkerClick={(e) => {
              e.preventDefault();
              let target = e.target;
              if (target.id === "richemont_map") {
                return;
              }
              while (
                target.dataset.id === undefined &&
                target.id !== "richemont_map"
              ) {
                target = target.parentNode;
              }
              const id = target.dataset.id;

              const index = boutiques.findIndex(
                (boutique) => boutique.frontmatter.id === parseInt(id)
              );

              if (index > -1) {
                setActiveIndex(index);
              }
            }}
          />
        </MapContainer>
      </Grid>
    </>
  );
};
export default BoutiqueMap;
