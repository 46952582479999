import React from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";

const blue = "rgb(4, 18, 36)";
const white = "#fff";

const LinkButton = styled.a`
  display: flex;
  padding: 15px 20px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.6;
  align-items: center;
  justify-content: center;
  max-width: 207px;
  text-decoration: none !important;
  transition: all 0.3s;
  width: 100%;

  background-color: none !important;
  border: 2px solid ${white};
  color: ${white} !important;

  &:hover,
  &:focus,
  &:active {
    background-color: ${white} !important;
    border: 2px solid ${blue};
    color: ${blue} !important;
  }
`;

const ItineraryButton = ({ href }) => {
  const intl = useIntl();
  return (
    <LinkButton target="_blank" rel="noreferrer noopener" href={href}>
      {intl.formatMessage({ id: "itinerary" })}
    </LinkButton>
  );
};

export default ItineraryButton;
