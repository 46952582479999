import React from "react";
import styled from "styled-components";
import { Box, Text } from "grommet";

const LogosGrid = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;

  > div {
    width: 33%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @media (min-width: 992px) {
      padding: 20px;
      width: 25%;
    }
  }
`;

const Link = styled.a`
  color: #444;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &:hover {
    text-decoration: underline;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin-bottom: 4px;
  transition: transform ease-in-out 0.3s;
  &:hover {
    transform: scale(1.1);
  }
`;

export default function getBoutiquesLogos({
  boutiques,
  language = "en",
  boutiqueName,
  white,
  showNames,
}) {
  let boutiquesLogos = [];

  boutiques.forEach((boutique, i) => {
    boutiquesLogos.push(
      <Box key={i}>
        <Link
          href={boutique.frontmatter.website}
          target="_blank"
          rel="noreferrer noopener"
        >
          <ImageWrapper>
            <img
              width="150px"
              src={
                white //si props "white"
                  ? boutique.frontmatter.logo.path.publicURL
                  : boutique.frontmatter.logo.black.publicURL
              }
              alt={boutique.frontmatter.logo.alt}
            />
          </ImageWrapper>
          {showNames ? (
            <Text color="#505050">{boutique.frontmatter.name}</Text>
          ) : (
            ""
          )}
        </Link>
      </Box>
    );
  });

  return boutiqueName ? (
    <>{boutiquesLogos}</>
  ) : (
    <Box>
      <LogosGrid>{boutiquesLogos}</LogosGrid>
    </Box>
  );
}
