import React from "react";
import RichemontMap from "./planRichemont";
import styled from "styled-components";
import { Button } from "grommet";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import useMediaQuery from "../../context/MediaQuery";

const StyledTransformComponent = styled(TransformComponent)`
  width: 100%;
  height: 100%;
`;

const StyledTransformWrapper = styled(TransformWrapper)`
  width: 100%;
  height: 100%;
`;

const StyledButton = styled(Button)`
  background-color: #00202F;
  border: none;
  outline: white;
  border-radius: 50%;
  width: 25px;
  font-size: 14px;
  font-weight: 300;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  color: white;
  height: 25px;
  margin: 0 10px 20px 0;
  justify-content: center;
  align-items: center;
    display: flex;
  }}

  @media (max-width: 768px) {
    width: 45px;
    height: 45px;
    margin: 0 15px 20px 0;
    font-size: 18px;
  }
`;

const Map = ({ onMarkerClick }) => {
  const isDesktopOrLaptop = useMediaQuery("(min-width: 768px)");

  return (
    <StyledTransformWrapper
      key={isDesktopOrLaptop ? "desktop" : "mobile"}
      initialScale={isDesktopOrLaptop ? 1.5 : 0.4}
      centerOnInit
      maxScale={2.5}
      minScale={isDesktopOrLaptop ? 1.5 : 0.3}
    >
      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
        <div
          style={{
            position: "relative",
            zIndex: "1",
            justifyContent: "center",
            display: "flex",
            width: "100%",
            height: "100%",
          }}
        >
          {/*
          <div
            className="tools"
            style={{
              display: "flex",
              position: "absolute",
              top: "20px",
              zIndex: "50",
            }}
          >
            <StyledButton onClick={() => zoomIn()}>+</StyledButton>
            <StyledButton onClick={() => zoomOut()}>-</StyledButton>
            <StyledButton onClick={() => resetTransform()}>x</StyledButton>
          </div>
*/}
          <StyledTransformComponent
            wrapperStyle={{ height: isDesktopOrLaptop ? "100%" : "300px" }}
          >
            <div>
              <RichemontMap onMarkerClick={onMarkerClick} />
            </div>
          </StyledTransformComponent>
        </div>
      )}
    </StyledTransformWrapper>
  );
};

export default Map;
